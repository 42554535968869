<template>
  <div class="view view--light" ref="view" v-resize="onResize">
    <v-row class="mt-1" v-if="customerAircraft">
      <v-col :cols="12" class="text-right">
        <v-btn @click.prevent="downloadPDF" :loading="isLoadingEquipmentListPdf" color="primary" text>
          <v-icon>mdi-download</v-icon> <span class="ml-3">{{ $t('btn.downloadPDF') }}</span></v-btn
        >
        <v-btn @click="toggleAside({ isClipped: true, isTemporary: false })" color="primary" text>
          <v-icon>mdi-filter-outline</v-icon> <span class="ml-3">{{ $t('toggleFilter') }}</span></v-btn
        >
      </v-col>
    </v-row>

    <v-card elevation="1" class="mt-3 row--table-container" v-if="customerAircraft">
      <v-row>
        <v-col :cols="12" class="py-0">
          <TableEquipmentList
            :aircraft="customerAircraft"
            :columns="filters.columns.length ? filters.columns : tableColumns"
            :height="tableHeight"
            :search-query="filters.query"
            @click-menu-item="onClickMenuItem"
            class="table__equipment-list"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-4" v-else>
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>

    <!-- Aside -->
    <v-navigation-drawer
      app
      :clipped="aside.isClipped"
      :temporary="aside.isTemporary"
      right
      v-model="aside.isVisible"
      :width="navDrawerWidth"
      :class="{ 'v-navigation-drawer--open': aside.isVisible }"
    >
      <div class="d-flex align-center justify-space-between py-2 pr-2 pl-4 bg-color-lighter-gray">
        <h3 class="body-1">{{ aside.headline }}</h3>
        <v-btn @click="aside.isVisible = false" icon depressed text> <v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
      <FilterBarListBase
        :tableColumns="tableColumns"
        :selectedColumns="initSelectedTableColumns"
        @change="values => (filters = Object.assign({}, values))"
        class="mt-3 px-3 is-inside-nav-drawer"
      />
    </v-navigation-drawer>

    <!-- Dialog -->
    <v-dialog
      v-model="dialog.isVisible"
      :width="dialogWidth"
      :fullscreen="dialogIsFullscreen"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 flex justify-space-between">
          <span>
            {{ $t('headline.Manuals') }}
          </span>
          <v-btn icon @click.prevent="dialog.isVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-skeleton-loader v-if="isLoadingManuals" type="table-thead,table-tbody"></v-skeleton-loader>
          <TableManuals v-else :items="manuals" :columns="manualsTableColumns" />
        </v-card-text>
        <v-card-actions v-if="false">
          <div class="text-center w-100 mt-3 mb-3">
            <router-link :to="{ name: MANUALS, query: { title: 'nav.manuals.All' } }">{{ $t('btn.showAllManuals') }}</router-link>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as SNACKBAR_MODULE } from '@/store/modules/snackbar';
import { MANUALS } from '@/router/route-types';
import * as FileService from '@/services/file';
import { getEquipmentListAsPdf } from '@/services/customerAircraft';
import tableMixin from '@/shared/mixins/table';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';
import FilterBarListBase from '@/components/FilterBar/FilterBarBase';
import TableEquipmentList from '@/components/Table/TableEquipmentList';
import TableManuals from '@/components/Table/TableManuals';

export default {
  name: 'ListEquipment',

  mixins: [tableMixin],

  components: {
    AlertNoAircraft,
    FilterBarListBase,
    TableEquipmentList,
    TableManuals
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`
    }),

    customerAircraftId() {
      return get(this.customerAircraft, 'id');
    },

    dialogIsFullscreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    dialogWidth() {
      let result = '100%';
      if (this.$vuetify.breakpoint.smAndUp) {
        result = '70%';
      }
      return result;
    },

    initSelectedTableColumns() {
      const defaultColumns = ['description', 'type', 'part_number', 'serial_number'];
      return this.tableColumns.filter(item => defaultColumns.includes(item.value));
    },

    manualsTableColumns() {
      return [
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Revision',
          value: 'revision'
        },
        {
          text: 'Date',
          value: 'date'
        }
      ];
    },

    navDrawerWidth() {
      let result = '80vw';

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = '30vw';
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        result = '25vw';
      }

      return result;
    },

    salesname() {
      return get(this.customerAircraft, 'aircraft.easa_types.salesname');
    },

    variant() {
      return get(this.customerAircraft, 'aircraft.easa_types.variant');
    },

    MANUALS() {
      return MANUALS;
    }
  },

  data() {
    return {
      aside: {
        headline: this.$t('Filters'),
        isClipped: false,
        isTemporary: true,
        isVisible: false
      },
      dialog: {
        isVisible: false
      },
      filters: {
        query: '',
        columns: []
      },
      isLoadingEquipmentListPdf: false,
      isLoadingManuals: false,
      manuals: [],
      tableColumns: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'PartNumber',
          value: 'part_number'
        },
        {
          text: 'SerialNumber',
          value: 'serial_number'
        },
        {
          text: 'LotNumber',
          value: 'lot_number'
        },
        {
          text: 'Position',
          value: 'position'
        },
        {
          text: 'Manufacturer',
          value: 'manufacturer'
        }
      ],
      tableHeight: ''
    };
  },

  mounted() {
    this.init();

    // Set inital table columns
    this.filters.columns = this.initSelectedTableColumns.slice();
  },

  methods: {
    async downloadPDF() {
      try {
        this.isLoadingEquipmentListPdf = true;
        const response = await getEquipmentListAsPdf(this.customerAircraftId);
        const { file } = response.data || {};

        if (file) {
          window.open(file, '_blank').focus();
          // Perhaps show modal with button to download pdf
        } else {
          const errors = response.errors || [];
          errors.forEach(error => {
            this.$store.dispatch(`${SNACKBAR_MODULE}/OPEN`, {
              text: error.message,
              timeout: 12000,
              color: 'black'
            });
          });
        }
      } catch (error) {
        console.log('----------', { error });
      } finally {
        this.isLoadingEquipmentListPdf = false;
      }
    },

    init() {
      this.onResize();
    },

    onClickMenuItem(item) {
      this.dialog.isVisible = true;
      const { id } = item.tmp;
      this.requestFiles(id);
    },

    async onResize() {
      await this.$nextTick(() => {
        this.setTableHeight(this.$refs['view'], 550, document.querySelector('.table__equipment-list table'));
      });
    },

    async requestFiles(cmpTemplateId) {
      console.log(cmpTemplateId);
      const filter = {
        _and: [
          {
            cmp_tmp: {
              components_id: {
                id: {
                  _eq: cmpTemplateId
                }
              }
            }
          },
          {
            base_tags: {
              base_tags_id: {
                value: {
                  _eq: 'manual'
                }
              }
            }
          }
        ]
      };

      try {
        this.isLoadingManuals = true;
        const { data = [] } = await FileService.getFiles(filter);
        this.manuals = FileService.transformRootLeafsTwoLevel(data.slice());
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoadingManuals = false;
      }
    },

    toggleAside(params = {}) {
      const { isClipped = false, isTemporary = true } = params;
      this.aside.headline = this.$t('Filters');
      this.aside.isClipped = isClipped;
      this.aside.isTemporary = isTemporary;
      this.aside.isVisible = !this.aside.isVisible;
    }
  },

  watch: {
    customerAircraftId() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped></style>
