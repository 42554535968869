<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon x-small v-bind="attrs" v-on="on" :class="{ 'btn--disabled': isDisabled }" :title="$t('changeSortOrder')">
        <v-icon :class="getSortIconClass(sortProps)">mdi-arrow-down</v-icon>
      </v-btn>
    </template>
    <v-list flat dense>
      <v-list-item v-for="(item, index) in items" :key="index" @click="onClickSortItem(item)">
        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import { get } from 'lodash';

export default {
  name: 'MenuTableSort',

  props: {
    items: {
      type: Array,
      required: true,
      validator(value) {
        let result = false;
        value.forEach(item => {
          Object.keys(item).forEach(key => {
            result = ['text', 'value', 'prop'].includes(key);
          });
        });
        return result;
      }
    },

    sortProps: {
      type: Array,
      required: true
    },

    sort: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },

  computed: {
    isDisabled() {
      let result = true;
      const prop = get(this.sort, 'prop', '');
      // const sortOrder = get(this.sort, 'value', '');
      if (this.sortProps.includes(prop)) {
        result = false;
      }

      return result;
    }
  },

  methods: {
    getSortIconClass(props) {
      let result = 'is-disabled';
      const prop = get(this.sort, 'prop', '');
      const sortOrder = get(this.sort, 'value', '');
      if (props.includes(prop)) {
        result = '';
        if (sortOrder === 'desc') {
          result = 'is-down';
        } else if (sortOrder === 'asc') {
          result = 'is-up';
        }
      }

      return result;
    },

    onClickSortItem(item) {
      console.log('click', item);
      this.$emit('change', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-icon {
  &.is-up {
    transform: rotate(180deg);
  }

  &.is-down {
    transform: rotate(0deg);
  }
}
</style>
