<template>
  <div class="view view--light">
    <h2 class="m-t-0 c-black">
      <span>{{ $t('Lists') }}</span>
    </h2>
  </div>
</template>

<script>
export default {
  name: 'ListsIndex',

  mounted() {
    this.init();
  },

  methods: {
    init() {}
  }
};
</script>
