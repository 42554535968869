<template>
  <v-simple-table fixed-header :height="height">
    <template v-slot:default>
      <thead>
        <tr>
          <th v-if="isColumnVisible('description')">
            {{ $t('Description') }}
          </th>
          <th v-if="isColumnVisible('type')">
            {{ $t('Type') }}
          </th>
          <th v-if="isColumnVisible('part_number')">
            {{ $t('PartNumber') }}
          </th>
          <th v-if="isColumnVisible('serial_number')">
            {{ $t('SerialNumber') }}
          </th>
          <th v-if="isColumnVisible('lot_number')">
            {{ $t('LotNumber') }}
          </th>
          <th v-if="isColumnVisible('position')">
            {{ $t('Position') }}
          </th>
          <th v-if="isColumnVisible('manufacturer')">
            {{ $t('Manufacturer') }}
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <!-- No data -->
        <tr v-if="!allComponents.length">
          <th colspan="7">
            <v-alert text dense type="info" class="mt-4">
              {{ $t('hint.noData') }}
            </v-alert>
          </th>
        </tr>

        <tr v-for="item in allComponents" :key="item.id">
          <td v-if="isColumnVisible('description')">
            <v-chip
              v-for="category in getSubCategoryNames(item)"
              :key="category.id"
              :ripple="false"
              small
              color="accent"
              :outlined="!item.isSerialized"
            >
              {{ category.name }}
            </v-chip>
          </td>
          <td v-if="isColumnVisible('type')">{{ get(item, 'tmp.type.name', '') }}</td>
          <td v-if="isColumnVisible('part_number')">
            <v-tooltip top v-if="hasNoData(get(item, 'tmp.type.part_number', ''))">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  &mdash;
                </span>
              </template>
              <span>{{ $t('hint.noDataProvidedFromManufacturer') }}</span>
            </v-tooltip>
            <span v-else>{{ get(item, 'tmp.type.part_number', '') }}</span>
          </td>
          <td v-if="isColumnVisible('serial_number')">
            <span v-if="get(item, 'serial_number')">
              {{ get(item, 'serial_number') }}
            </span>
            <span v-else>&mdash;</span>
          </td>
          <td v-if="isColumnVisible('lot_number')">
            <span v-if="get(item, 'lot_number')">
              {{ get(item, 'lot_number') }}
            </span>
            <span v-else>&mdash;</span>
          </td>
          <td v-if="isColumnVisible('position')">
            <span v-if="get(item, 'position')">
              {{ getTranslationForItem(get(item, 'position'), currentUser.language).name }}
            </span>
            <span v-else>&nbsp;</span>
          </td>
          <td v-if="isColumnVisible('manufacturer')">
            <v-chip
              v-for="company in getManufacturers(item)"
              :key="company.id"
              :ripple="false"
              small
              color="lime lighten-3"
              :outlined="false"
            >
              {{ company.alias }}
            </v-chip>
          </td>
          <td style="text-align:right">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon depressed v-bind="attrs" v-on="on" :ripple="false">
                  <v-icon dark>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.prevent="onClickItem(item)" tag="button" :ripple="false">
                  <v-list-item-title>{{ $t('btn.showManuals') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { getTranslationForItem } from '@/services/utils';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';

export default {
  name: 'TableEquipmentList',

  props: {
    aircraft: {
      type: Object,
      required: false
    },

    columns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },

    height: {
      type: String,
      required: false,
      default: ''
    },

    searchQuery: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      currentUser: `${USER_MODULE}/currentUser`
    }),

    allComponents() {
      let result = [...this.serializedComponents, ...this.unserializedComponents];

      // Respect search query
      if (this.searchQuery) {
        const searchQuery = this.searchQuery.toUpperCase();
        result = result.filter(item => {
          return (
            item.lot_number?.toUpperCase().includes(searchQuery) ||
            item.serial_number?.toUpperCase().includes(searchQuery) ||
            item.position?.name.toUpperCase().includes(searchQuery) ||
            item.tmp?.type?.name.toUpperCase().includes(searchQuery) ||
            item.tmp?.type?.part_number.toUpperCase().includes(searchQuery)
          );
        });
      }

      return result;
    },

    columnValues() {
      const result = [];
      this.columns.forEach(column => {
        const { value } = column;
        if (value) {
          result.push(value);
        }
      });
      return result;
    },

    serializedComponents() {
      const result = [];

      const componentRelations = get(this.aircraft, 'cmp_serialized', []);
      if (componentRelations.length) {
        componentRelations.forEach(componentRelation => {
          const component = get(componentRelation, 'cmp_serialized_id');
          if (component) {
            component.isSerialized = true;
            result.push(component);
          }
        });
      }

      // Sort by sort order
      result.sort((a, b) => {
        const sortA = a.sort;
        const sortB = b.sort;

        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }
        return 0;
      });

      return result;
    },

    unserializedComponents() {
      const result = [];

      const componentRelations = get(this.aircraft, 'cmp_unser', []);
      if (componentRelations.length) {
        componentRelations.forEach(componentRelation => {
          const component = get(componentRelation, 'cmp_unserialized_id');
          if (component) {
            component.isSerialized = false;
            result.push(component);
          }
        });
      }

      // Sort by subcategory name
      result.sort((a, b) => {
        const subCatNameA = get(a, 'tmp.type.sub_cat[0].cmp_sub_cat_id.name', '').toUpperCase();
        const subCatNameB = get(b, 'tmp.type.sub_cat[0].cmp_sub_cat_id.name', '').toUpperCase();
        if (subCatNameA < subCatNameB) {
          return -1;
        }
        if (subCatNameA > subCatNameB) {
          return 1;
        }
        return 0;
      });

      return result;
    }
  },

  methods: {
    get,

    getTranslationForItem,

    getManufacturers(item) {
      const result = [];
      const relations = get(item, 'tmp.manufacturer', []);
      if (relations.length) {
        relations.forEach(relation => {
          const { companies_id } = relation;
          if (companies_id) {
            result.push(companies_id);
          }
        });
      }
      return result;
    },

    getMainCategoryNames(item) {
      const result = [];
      const relations = get(item, 'tmp.type.main_cat', []);
      if (relations.length) {
        relations.forEach(relation => {
          const { cmp_main_cat_id } = relation;
          if (cmp_main_cat_id) {
            result.push(cmp_main_cat_id);
          }
        });
      }
      return result;
    },

    getSubCategoryNames(item) {
      const result = [];
      const relations = get(item, 'tmp.type.sub_cat', []);
      if (relations.length) {
        relations.forEach(relation => {
          const { cmp_sub_cat_id } = relation;
          if (cmp_sub_cat_id) {
            const translatedItem = getTranslationForItem(cmp_sub_cat_id, this.currentUser.language);
            result.push(translatedItem);
          }
        });
      }
      return result;
    },

    hasNoData(value) {
      const placeholders = ['without', '+kA+', '+unknown+', '-', '--', '---'];

      return placeholders.includes(value);
    },

    isColumnVisible(value) {
      return this.columnValues.includes(value);
    },

    onClickItem(item) {
      this.$emit('click-menu-item', item);
    }
  }
};
</script>

<style lang="scss" scoped></style>
