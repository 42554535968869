<template>
  <div class="view view--light" ref="view" v-resize="onResize">
    <!-- Card with filters -->
    <!-- Currently not in use -->
    <v-card elevation="1" class="mt-3" v-if="customerAircraft && false">
      <v-card-text>
        <FilterBarListAD
          :filterOptions="filterOptions"
          :tableColumns="tableColumns"
          :selectedColumns="initSelectedTableColumns"
          @change="values => (filters = Object.assign({}, values))"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
        <v-btn @click="aside.isVisible = !aside.isVisible" depressed width="100%" v-else>
          <v-icon>mdi-filter-outline</v-icon> <span class="ml-3">{{ $t('toggleFilter') }}</span></v-btn
        >
      </v-card-text>
    </v-card>

    <v-row class="mt-1" v-if="customerAircraft">
      <v-col :cols="12" class="text-right">
        <v-btn @click.prevent="downloadPDF" :loading="isLoadingADListPdf" color="primary" text>
          <v-icon>mdi-download</v-icon> <span class="ml-3">{{ $t('btn.downloadPDF') }}</span></v-btn
        >
        <v-btn @click="toggleAside({ isClipped: true, isTemporary: false })" color="primary" text>
          <v-icon>mdi-filter-outline</v-icon> <span class="ml-3">{{ $t('toggleFilter') }}</span></v-btn
        >
      </v-col>
    </v-row>

    <v-card v-if="customerAircraft" elevation="1" class="mt-3 row--table-container" ref="tableAdContainer">
      <v-row>
        <v-col :cols="12" class="py-0">
          <v-skeleton-loader type="table-thead,table-tbody" v-if="isLoading"></v-skeleton-loader>
          <TableAirworthinessDirectiveOverview
            :items="filteredFiles"
            :height="tableHeight"
            :columns="filters.columns.length ? filters.columns : tableColumns"
            class="table__ad-overview"
            v-else
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-3" v-else>
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>

    <!-- Aside -->
    <v-navigation-drawer
      app
      :clipped="aside.isClipped"
      :temporary="aside.isTemporary"
      right
      v-model="aside.isVisible"
      :width="navDrawerWidth"
      :class="{ 'v-navigation-drawer--open': aside.isVisible }"
    >
      <div class="d-flex align-center justify-space-between py-2 pr-2 pl-4 bg-color-lighter-gray">
        <h3 class="body-1">{{ aside.headline }}</h3>
        <v-btn @click="aside.isVisible = false" icon depressed text> <v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
      <FilterBarListAD
        :easa-type="easaType"
        :filterOptions="filterOptions"
        :tableColumns="tableColumns"
        :selectedColumns="initSelectedTableColumns"
        @change="values => (filters = Object.assign({}, values))"
        class="mt-3 px-3 is-inside-nav-drawer"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as SNACKBAR_MODULE } from '@/store/modules/snackbar';
import { MODULE_NAME as TAGS_MODULE } from '@/store/modules/tags';
import * as FileService from '@/services/file';
import tableMixin from '@/shared/mixins/table';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';
import FilterBarListAD from '@/components/FilterBar/FilterBarListAD';
import TableAirworthinessDirectiveOverview from '@/components/Table/TableAirworthinessDirectiveOverview';

export default {
  name: 'ListAD',

  mixins: [tableMixin],

  components: {
    AlertNoAircraft,
    FilterBarListAD,
    TableAirworthinessDirectiveOverview
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`
    }),

    customerAircraftId() {
      return get(this.customerAircraft, 'id');
    },

    easaType() {
      return get(this.customerAircraft, 'aircraft.easa_types', {});
    },

    easaTypeId() {
      return get(this.customerAircraft, 'aircraft.easa_types.id');
    },

    easaTypeName() {
      return get(this.customerAircraft, 'aircraft.easa_types.name');
    },

    filterOptions() {
      const result = {
        applicable: [],
        tags: []
      };

      this.files.forEach(file => {
        const { ad_sb_applicable, base_tags = [] } = file;

        // applicable
        if (ad_sb_applicable && !result.applicable.includes(ad_sb_applicable)) {
          result.applicable.push(ad_sb_applicable);
        }

        // tags
        base_tags.forEach(tagRelation => {
          const { base_tags_id: tag } = tagRelation;
          const { id } = tag;
          const found = result.tags.find(item => {
            return item.id === id;
          });
          if (!found) {
            result.tags.push(tag);
          }
        });
      });
      return result;
    },

    filteredFiles() {
      let result = this.files.slice();
      const { query = '', applicable = [], tags = [], onlyEasaType = false } = this.filters;

      // Only TMs
      // result = result.filter(item => {
      //   let result = false;
      //   const tags = get(item, 'base_tags', []);
      //   tags.forEach(tag => {
      //     const tagId = get(tag, 'base_tags_id.id');
      //     if (this.tagTMId.includes(tagId)) {
      //       result = true;
      //     }
      //   });
      //   return result;
      // });

      // Filter only main items without reference
      result = result.filter(file => {
        return !file.references || !file.references.length;
      });

      // search query for props: [title, description]
      if (query && query.length) {
        result = result.filter(file => {
          const title = file.title ? file.title.toLowerCase() : '';
          const description = file.description ? file.description.toLowerCase() : '';

          // search inside airworthinessDirectives for props: [title, description]
          const airworthinessDirectives = file.airworthinessDirectives.filter(item => {
            const itemTitle = item.title || '';
            const itemDescription = item.description || '';
            return itemTitle.includes(query.toLowerCase()) || itemDescription.includes(query.toLowerCase());
          });

          return title.includes(query.toLowerCase()) || description.includes(query.toLowerCase()) || airworthinessDirectives.length;
        });
      }

      // applicable props: ad_sb_applicable
      if (applicable && applicable.length) {
        result = result.filter(file => {
          return applicable.includes(file.ad_sb_applicable);
        });
      }

      // Respect tags and tags in ADs
      if (tags && tags.length) {
        result = result.filter(file => {
          let result = false;
          const { airworthinessDirectives = [], base_tags = [] } = file;
          // Check each tag for the tagId
          base_tags.forEach(item => {
            const id = item.base_tags_id?.id;
            if (tags.includes(id)) {
              result = true;
            }
          });

          // Check each tag for the tagId for corresponding ADs
          airworthinessDirectives.forEach(ad => {
            const { base_tags: ad_base_tags = [] } = ad;
            ad_base_tags.forEach(item => {
              const id = item.base_tags_id?.id;
              if (tags.includes(id)) {
                result = true;
              }
            });
          });

          return result;
        });
      }

      // Respect only easa type checkbox
      if (onlyEasaType) {
        result = result.filter(file => {
          let result = false;
          const { easa_types = [] } = file;
          easa_types.forEach(relation => {
            const id = relation?.easa_types_id?.id;
            if (id === this.easaTypeId) {
              result = true;
            }
          });

          return result;
        });
      }

      return result;
    },

    initSelectedTableColumns() {
      const defaultColumns = ['tm', 'subject', 'repetetive', 'applicable', 'complied', 'option', 'previous-complied', 'check'];
      if (this.$vuetify.breakpoint.width > 1310) {
        defaultColumns.unshift('ad');
      }
      return this.tableColumns.filter(item => defaultColumns.includes(item.value));
    },

    navDrawerWidth() {
      let result = '80vw';

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = '30vw';
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        result = '25vw';
      }

      return result;
    },

    salesname() {
      return get(this.customerAircraft, 'aircraft.easa_types.salesname');
    },

    tagIds() {
      const tagAbbrs = ['tm', 'ad', 'lta'];
      return this.$store.getters[`${TAGS_MODULE}/tagIdsByAbbrs`](tagAbbrs);
    },

    tagTMId() {
      const tagAbbrs = ['tm'];
      return this.$store.getters[`${TAGS_MODULE}/tagIdsByAbbrs`](tagAbbrs);
    },

    variant() {
      return get(this.customerAircraft, 'aircraft.easa_types.variant');
    }
  },

  data() {
    return {
      aside: {
        component: '',
        headline: this.$t('Filters'),
        isClipped: false,
        isTemporary: true,
        isVisible: false
      },
      files: [],
      filters: {
        query: '',
        applicable: [],
        columns: [],
        onlyEasaType: false
      },
      isLoading: false,
      isLoadingADListPdf: false,
      tableColumns: [
        {
          text: 'abbr.AD',
          value: 'ad'
        },
        {
          text: 'abbr.LTA',
          value: 'lta'
        },
        {
          text: 'abbr.TM',
          value: 'tm'
        },
        {
          text: 'Series',
          value: 'series'
        },
        {
          text: 'SerialNumbers',
          value: 'serialNumber'
        },
        {
          text: 'Subject',
          value: 'subject'
        },
        {
          text: 'Deadline',
          value: 'deadline'
        },
        {
          text: 'Repetetive',
          value: 'repetetive'
        },
        {
          text: 'notApplicable',
          value: 'applicable'
        },
        {
          text: 'Option',
          value: 'option'
        },
        {
          text: 'complied',
          value: 'complied'
        },
        {
          text: 'PreviousComplied',
          value: 'previous-complied'
        },
        {
          text: 'Check',
          value: 'check'
        }
      ],
      tableHeight: ''
    };
  },

  mounted() {
    if (!this.customerAircraftId) {
      return;
    }

    this.init();

    // Set inital table columns
    this.filters.columns = this.initSelectedTableColumns.slice();
  },

  methods: {
    async downloadPDF() {
      try {
        this.isLoadingADListPdf = true;
        const response = await FileService.getADsForAircraft({
          download: true,
          customerAircraft: this.customerAircraftId,
          easaTypeName: this.easaTypeName
        });
        const { file } = response.data || {};

        if (file) {
          window.open(file, '_blank').focus();
          // Perhaps show modal with button to download pdf
        } else {
          const errors = response.errors || [];
          errors.forEach(error => {
            this.$store.dispatch(`${SNACKBAR_MODULE}/OPEN`, {
              text: error.message,
              timeout: 12000,
              color: 'black'
            });
          });
        }
      } catch (error) {
        console.log('----------', { error });
      } finally {
        this.isLoadingADListPdf = false;
      }
    },

    // @Todo: Remove if all went well with new endpoint /files/ad
    // getFilesWithReferences(files) {
    //   const result = [];
    //   files.forEach(file => {
    //     const { id } = file;
    //     file.airworthinessDirectives = [];

    //     files.forEach(f => {
    //       const references = get(f, 'references', []);
    //       if (references.length) {
    //         references.forEach(referenceRelation => {
    //           const refFileId = get(referenceRelation, 'related_directus_files_id.id', '');
    //           if (refFileId === id) {
    //             file.airworthinessDirectives.push(f);
    //           }
    //         });
    //       }
    //     });

    //     result.push(file);
    //   });

    //   return result;
    // },

    init() {
      this.requestFiles();
      this.onResize();
    },

    async onResize() {
      await this.$nextTick(() => {
        this.setTableHeight(this.$refs['view'], 550, document.querySelector('.table__ad-overview table'));
      });
    },

    /**
     * Filter by selected customer aircraft, the easa type of the selected customer aircraft and the tags for ADs
     *
     * @return  {Array}
     */
    async requestFiles() {
      try {
        this.isLoading = true;
        // @Todo: Remove if all went well with new endpoint /files/ad
        // const filter = {
        //   _or: [
        //     {
        //       cust_aircraft: {
        //         cust_aircrafts_id: {
        //           _eq: this.customerAircraftId
        //         }
        //       }
        //     },
        //     {
        //       easa_types: {
        //         easa_types_id: {
        //           name: {
        //             _eq: this.easaTypeName
        //           }
        //         }
        //       }
        //     }
        //   ],
        //   base_tags: {
        //     base_tags_id: {
        //       _in: this.tagIds
        //     }
        //   }
        // };
        // const response = await FileService.getADsForAircraft(filter);
        // const { data = [] } = response;
        // this.files = this.getFilesWithReferences(data);

        const { data = [] } = await FileService.getADsForAircraft({
          customerAircraft: this.customerAircraftId,
          easaTypeName: this.easaTypeName
        });
        this.files = [...data];

        setTimeout(() => {
          this.onResize();
        }, 0);
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    toggleAside(params = {}) {
      // reset aside settings
      // this.aside.component = '';
      // this.aside.headline = this.$t('Filters');
      // this.aside.isClipped = false;
      // this.aside.isTemporary = true;

      const { component = '', isClipped = false, isTemporary = true } = params;
      this.aside.component = component;
      this.aside.headline = this.$t('Filters');
      this.aside.isClipped = isClipped;
      this.aside.isTemporary = isTemporary;

      if (component === 'tableColumns') {
        this.aside.component = component;
        this.aside.isClipped = true;
        this.aside.isTemporary = false;
        this.aside.headline = this.$t('selectColumns');
      }

      this.aside.isVisible = !this.aside.isVisible;
    }
  },

  watch: {
    customerAircraftId() {
      this.init();
    },

    filteredFiles() {
      setTimeout(() => {
        this.onResize();
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer--open {
  z-index: 9999999999;
}
</style>
