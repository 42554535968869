<template>
  <v-simple-table fixed-header :height="height" class="table__ad-overview">
    <template v-slot:default>
      <thead>
        <tr>
          <th style="min-width: 7rem;" v-if="isColumnVisible('ad')">{{ $t('abbr.AD') }}</th>
          <th style="min-width: 7rem;" v-if="isColumnVisible('lta')">{{ $t('abbr.LTA') }}</th>
          <th style="min-width: 7rem;" v-if="isColumnVisible('tm')">
            {{ $t('abbr.TM') }}

            <v-btn icon x-small :title="$t('changeSortOrder')" @click="onClickSort('sort')">
              <v-icon :class="getSortIconClass(['sort'])">mdi-arrow-down</v-icon>
            </v-btn>
          </th>
          <th style="min-width: 12rem;" v-if="isColumnVisible('series')">
            {{ $t('Series') }}
          </th>
          <th style="min-width: 8rem;" v-if="isColumnVisible('serialNumber')">
            {{ $t('SerialNumbers') }}
          </th>
          <th style="min-width: 14rem;" v-if="isColumnVisible('subject')">
            {{ $t('Subject') }}
          </th>
          <th style="min-width: 7rem" v-if="isColumnVisible('deadline')">
            {{ $t('Deadline') }}

            <MenuTableSort :items="sortDueDateProps" :sortProps="dueDateSortProps" :sort="currentSort" @change="onClickSortItem" />
          </th>
          <th v-if="isColumnVisible('repetetive')" class="column-head--reduced">
            {{ $t('repetetive') }}
          </th>
          <th v-if="isColumnVisible('applicable')" class="text-center column-head--reduced">
            <div class="subtable-ad-overview-head-col">
              {{ $t('notApplicable') }}
            </div>
          </th>
          <th v-if="isColumnVisible('option')" class="text-center column-head--reduced">
            <div class="subtable-ad-overview-head-col">
              {{ $t('Option') }}
            </div>
          </th>
          <th v-if="isColumnVisible('complied')" class="text-center column-head--reduced">
            <div class="subtable-ad-overview-head-col">
              {{ $t('complied') }}
            </div>
          </th>
          <th v-if="isColumnVisible('previous-complied')" class="text-center column-head--reduced">
            <div class="subtable-ad-overview-head-col">
              {{ $t('PreviousComplied') }}
            </div>
          </th>
          <th v-if="isColumnVisible('check')" class="text-center">
            <div class="subtable-ad-overview-head-col is-col-check">
              {{ $t('Check') }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- No data -->
        <tr v-if="!items.length">
          <th colspan="8">
            <v-alert text dense type="info" class="mt-4">
              {{ $t('hint.noData') }}
            </v-alert>
          </th>
        </tr>

        <!-- All Items -->
        <tr v-for="(item, i) in sortedItems" :key="item.id" :ref="`row-${i}`">
          <!-- AD -->
          <td v-if="isColumnVisible('ad')">
            <ul class="list">
              <li v-for="ad in item.airworthinessDirectives.filter(i => hasTags(i, ['airworthiness-directive']))" :key="ad.id">
                <a
                  :href="getAssetUrl(ad.id) + `/${ad.filename_download}` + '?access_token=' + authToken"
                  class="file-item__link d-flex flex-column align-items-center py-2"
                  target="_blank"
                  :title="ad.status?.name === 'not valid (superseded)' ? $t('label.superseded') : ''"
                >
                  <div class="d-flex" :class="{ 'is-superseded': ad.status?.name === 'not valid (superseded)' }">
                    {{ ad.title }}
                  </div>

                  <div class="mt-1 d-flex flex-column" :class="{ 'is-superseded': ad.status?.name === 'not valid (superseded)' }">
                    <span v-if="ad.published_at" class="caption">
                      {{ $d(new Date(ad.published_at), 'short') }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </td>
          <td v-if="isColumnVisible('lta')">
            <ul class="list">
              <li v-for="ad in item.airworthinessDirectives.filter(i => hasTags(i, ['lufttuechtigkeitsanweisung']))" :key="ad.id">
                <a
                  :href="getAssetUrl(ad.id) + `/${ad.filename_download}` + '?access_token=' + authToken"
                  class="file-item__link d-flex flex-column align-items-center py-2"
                  target="_blank"
                  :title="ad.status?.name === 'not valid (superseded)' ? $t('label.superseded') : ''"
                >
                  <div class="d-flex" :class="{ 'is-superseded': ad.status?.name === 'not valid (superseded)' }">
                    {{ ad.title }}
                  </div>

                  <div class="mt-1 d-flex flex-column" :class="{ 'is-superseded': ad.status?.name === 'not valid (superseded)' }">
                    <span v-if="ad.published_at" class="caption">
                      {{ $d(new Date(ad.published_at), 'short') }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </td>
          <!-- TM -->
          <td v-if="isColumnVisible('tm')">
            <a
              v-if="hasTagTM(item)"
              :href="getAssetUrl(item.id) + `/${item.filename_download}` + '?access_token=' + authToken"
              class="file-item__link d-flex flex-column align-items-center py-2"
              target="_blank"
              :title="item.status?.name === 'not valid (superseded)' ? $t('label.superseded') : ''"
            >
              <div class="d-flex" :class="{ 'is-superseded': item.status?.name === 'not valid (superseded)' }">
                {{ item.title }}
              </div>

              <div class="mt-1 d-flex flex-column" :class="{ 'is-superseded': item.status?.name === 'not valid (superseded)' }">
                <span v-if="item.published_at" class="caption">
                  {{ $d(new Date(item.published_at), 'short') }}
                </span>
              </div>
            </a>
          </td>
          <td v-if="isColumnVisible('series')">
            <ul class="list" v-if="getVariants(item).length">
              <li v-for="(variant, index) in getVariants(item)" :key="index">
                {{ variant }}
              </li>
            </ul>
            <span v-else>{{ $t('all') }}</span>
          </td>
          <td v-if="isColumnVisible('serialNumber')">
            <span v-if="item.ad_sb_serialnumber">{{ item.ad_sb_serialnumber }}</span>
          </td>
          <td v-if="isColumnVisible('subject')">
            {{
              getTranslatedItemProp({ item, prop: 'description', code: userLanguage }) ||
                item.description + ' (' + $t('text.missingTranslation') + ')'
            }}
          </td>
          <td v-if="isColumnVisible('deadline')">
            <div class="d-flex flex-column">
              <span v-if="item.ad_sb_applicable">{{ $t(item.ad_sb_applicable) }}</span>
              <span v-if="item.due_date">{{ $d(new Date(item.due_date), 'short') }}</span>
            </div>
          </td>
          <td v-if="isColumnVisible('repetetive')" class="text-center">
            <v-icon v-if="item.ad_sb_is_repetitive">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </td>
          <th :colspan="countVisibleActionColumns" class="px-0" v-if="countVisibleActionColumns > 0">
            <table class="table__ad-overview__subtable w-100" v-if="item.ad_sb_action.length > 0">
              <tbody>
                <!-- Show blank rectangles -->
                <tr v-if="item.ad_sb_action.filter(action => action.customer_aircrafts_id === customerAircraft.id).length < 1">
                  <td class="text-center pa-1" v-if="isColumnVisible('applicable')">
                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-center pa-1" v-if="isColumnVisible('option')">
                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-center pa-1" v-if="isColumnVisible('complied')">
                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-center pa-1" v-if="isColumnVisible('previous-complied')">
                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-center is-col-check" v-if="isColumnVisible('check')">
                    <div class="pa-2 caption">
                      &nbsp;
                    </div>
                  </td>
                </tr>

                <tr
                  v-for="actionItem in item.ad_sb_action.filter(action => action.customer_aircrafts_id === customerAircraft.id)"
                  :key="actionItem.id"
                >
                  <td v-if="isColumnVisible('applicable')" class="text-center pa-1">
                    <v-icon v-if="actionItem.not_applicable">mdi-checkbox-marked</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td v-if="isColumnVisible('option')" class="text-center pa-1">
                    <v-icon v-if="actionItem.option">mdi-checkbox-marked</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td v-if="isColumnVisible('complied')" class="text-center pa-1">
                    <v-icon v-if="actionItem.complied">mdi-checkbox-marked</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td v-if="isColumnVisible('previous-complied')" class="text-center pa-1">
                    <v-icon v-if="actionItem.previous_complied">mdi-checkbox-marked</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-center is-col-check" :class="{ 'is-superseded': actionItem.superseded }" v-if="isColumnVisible('check')">
                    <div class="pa-2 caption">
                      <div>{{ $d(new Date(actionItem.date), 'short') }}</div>
                      <div>{{ actionItem.user }}</div>
                      <a
                        v-if="actionItem.reference"
                        target="_blank"
                        :href="
                          getAssetUrl(actionItem.reference.id) + `/${actionItem.reference.filename_download}` + '?access_token=' + authToken
                        "
                        :title="actionItem.reference.title"
                      >
                        <div>{{ actionItem.description || actionItem.reference.title }}</div>
                      </a>
                      <div v-else>{{ actionItem.description }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table__ad-overview__subtable w-100" v-else>
              <tbody>
                <tr>
                  <td
                    class="text-center pa-1"
                    v-for="n in columnValues.some(c => c === 'check') ? countVisibleActionColumns - 1 : countVisibleActionColumns"
                    :key="n"
                  >
                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td class="text-center is-col-check" v-if="isColumnVisible('check')">
                    <div class="pa-2 caption">
                      &nbsp;
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { get, sortBy } from 'lodash';
import { mapGetters } from 'vuex';
import { getTranslatedItemProp } from '@/services/file';
import { getAssetUrl } from '@/services/utils';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as TAGS_MODULE } from '@/store/modules/tags';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import MenuTableSort from '@/components/Menu/MenuTableSort';

export default {
  name: 'TableAirworthinessDirectiveOverview',

  components: {
    MenuTableSort
  },

  props: {
    columns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },

    height: {
      type: String,
      required: false,
      default: ''
    },

    items: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`,
      userLanguage: `${USER_MODULE}/currentUserLanguage`
    }),

    authToken() {
      return localStorage.getItem('auth_token') || '';
    },

    columnValues() {
      const result = [];
      this.columns.forEach(column => {
        const { value } = column;
        if (value) {
          result.push(value);
        }
      });
      return result;
    },

    countVisibleActionColumns() {
      const columns = ['applicable', 'option', 'complied', 'previous-complied', 'check'];
      const result = this.columnValues.filter(col => {
        return columns.includes(col);
      });
      return result.length;
    },

    sortedItems() {
      let result = this.items.slice();
      const prop = get(this.currentSort, 'prop');
      const sortOrder = get(this.currentSort, 'value');
      if (this.items.length && prop && sortOrder) {
        result = sortBy(this.items, item => {
          return item[prop];
        });
      }

      if (sortOrder === 'desc') {
        result.reverse();
      }
      return result;
    },

    sortDueDateProps() {
      return this.sort.filter(o => this.dueDateSortProps.includes(o.prop));
    },

    sortTitleProps() {
      return this.sort.filter(o => this.titleSortProps.includes(o.prop));
    },

    tagTMId() {
      const tagAbbrs = ['tm'];
      return this.$store.getters[`${TAGS_MODULE}/tagIdsByAbbrs`](tagAbbrs);
    }
  },

  data() {
    return {
      currentSort: {
        text: 'sort.asc',
        value: 'asc',
        prop: 'sort'
      },
      sort: [
        {
          text: 'sort.title.asc',
          value: 'asc',
          prop: 'title'
        },
        {
          text: 'sort.title.desc',
          value: 'desc',
          prop: 'title'
        },
        {
          text: 'sort.published_at.asc',
          value: 'asc',
          prop: 'published_at'
        },
        {
          text: 'sort.published_at.desc',
          value: 'desc',
          prop: 'published_at'
        },
        {
          text: 'sort.due_date.asc',
          value: 'asc',
          prop: 'due_date'
        },
        {
          text: 'sort.due_date.desc',
          value: 'desc',
          prop: 'due_date'
        }
      ],
      dueDateSortProps: ['due_date'],
      titleSortProps: ['title', 'published_at']
    };
  },

  methods: {
    get,

    getAssetUrl,

    getTranslatedItemProp,

    getSortIconClass(props) {
      let result = '';
      const prop = get(this.currentSort, 'prop', '');
      const sortOrder = get(this.currentSort, 'value', '');
      if (props.includes(prop)) {
        result = '';
        if (sortOrder === 'desc') {
          result = 'is-down';
        } else if (sortOrder === 'asc') {
          result = 'is-up';
        }
      }

      return result;
    },

    getVariants(item) {
      const result = [];
      const easaTypes = get(item, 'easa_types', []);
      easaTypes.forEach(relationItem => {
        const variant = get(relationItem, 'easa_types_id.variant');
        if (variant) {
          result.push(variant);
        }
      });
      return result;
    },

    hasTags(item, values) {
      let result = false;
      const tags = get(item, 'base_tags', []);
      tags.forEach(tag => {
        const value = get(tag, 'base_tags_id.value');
        if (values.includes(value)) {
          result = true;
        }
      });
      return result;
    },

    hasTagTM(item) {
      let result = false;
      const tags = get(item, 'base_tags', []);
      tags.forEach(tag => {
        const tagId = get(tag, 'base_tags_id.id');
        if (this.tagTMId.includes(tagId)) {
          result = true;
        }
      });
      return result;
    },

    isColumnVisible(value) {
      return this.columnValues.includes(value);
    },

    onClickSortItem(item) {
      this.currentSort = Object.assign({}, item);
    },

    onClickSort(propName) {
      const currentDirection = get(this.currentSort, 'value', 'asc');
      const direction = currentDirection === 'asc' ? 'desc' : 'asc';
      this.currentSort = {
        text: `sort.${direction}`,
        value: `${direction}`,
        prop: `${propName}`
      };
    }
  }
};
</script>

<style lang="scss">
.file-item__link {
  &:hover {
    text-decoration: underline;
  }
}

.table__ad-overview {
  --subtable-ad-overview-head-col-with: 40px;
  --subtable-ad-overview-head-col-check-with: 120px;

  thead {
    th {
      .btn--disabled {
        opacity: 0;
      }

      &:hover {
        .btn--disabled {
          opacity: 1;
        }
      }
    }
  }

  .column-head {
    &--reduced {
      @media screen and (max-width: 1380px) {
        hyphens: auto;
      }
    }
  }

  .v-icon {
    &.is-up {
      transform: rotate(180deg);
    }

    &.is-down {
      transform: rotate(0deg);
    }
  }

  .is-superseded {
    text-decoration: line-through;
  }

  td.is-superseded {
    &:not(.is-col-check) {
      margin: 0 -1rem;
      position: relative;

      &::before {
        content: '';
        background-color: var(--c-gray);
        position: absolute;
        top: 50%;
        right: 1rem;
        left: 0;
        z-index: 1;
        transform: translate3d(0, 50%, 0);
        height: 1px;
        width: 100%;
      }
    }

    > * {
      color: var(--c-medium-gray);
    }

    &.is-col-check {
      text-decoration: line-through;
    }
  }
}

.subtable-ad-overview-head-col {
  hyphens: auto;

  &:not(.is-col-check) {
    width: var(--subtable-ad-overview-head-col-with);
  }

  &.is-col-check {
    width: var(--subtable-ad-overview-head-col-check-with);
  }
}

.table__ad-overview__subtable {
  border-collapse: collapse;

  tr {
    &:not(:first-of-type) {
      td {
        border-top: 1px solid var(--c-medium-gray);
      }
    }
  }

  td {
    &:not(.is-col-check) {
      width: var(--subtable-ad-overview-head-col-with);
    }

    &.is-col-check {
      width: calc(var(--subtable-ad-overview-head-col-check-with) - 32px);
      max-width: calc(var(--subtable-ad-overview-head-col-check-with) - 32px);
    }
  }
}
</style>
